import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useData } from '../../contexts/DataContext';
import ShopLayout from '../../components/layout/ShopLayout';
import './ProductDetail.css';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { algerianWilayas } from '../../data/wilayas';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ProductDetail() {
  const { id } = useParams();
  const { products, categories, shippingZones, loading } = useData();
  const [selectedImage, setSelectedImage] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [orderForm, setOrderForm] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    wilaya: '',
    city: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const [suggestedProducts, setSuggestedProducts] = useState([]);

  const product = products.find(p => p.id === id);
  const category = product ? categories.find(c => c.id === product.categoryId) : null;

  useEffect(() => {    
    if (product) {        
      const categoryProducts = products
        .filter(p => p.categoryId === product.categoryId && p.id !== product.id && p.isActive)
        .slice(0, 4);
      setSuggestedProducts(categoryProducts);
    }
  }, [product, products]);

  const isValidAlgerianPhone = (phone) => {
    const phoneRegex = /^(?:\+213|0)[5-7]\d{8}$/;
    return phoneRegex.test(phone);
  };

  if (loading) {
    return (
      <ShopLayout>
        <div className="loading-container">
          <div className="loading-content">
            <i className="fas fa-spinner fa-spin"></i>
            <p>Chargement du produit...</p>
          </div>
        </div>
      </ShopLayout>
    );
  }

  if (!product) {
    return (
      <ShopLayout>
        <div className="error-container">
          <h2>Produit non trouvé</h2>
          <p>Le produit que vous recherchez n'existe pas.</p>
        </div>
      </ShopLayout>
    );
  }

  const handleOrderChange = (e) => {
    setOrderForm({
      ...orderForm,
      [e.target.name]: e.target.value
    });
  };

  const calculateTotal = () => {
    const productTotal = product.price * quantity;
    
    if (product.freeShipping) {
      return productTotal;
    }
    
    const shippingZone = shippingZones.find(zone => zone.id === orderForm.wilaya);
    const shippingCost = shippingZone?.price || 0;
    
    return productTotal + shippingCost;
  };

  const handleSubmitOrder = async (e) => {
    e.preventDefault();
    
    if (!orderForm.firstName.trim()) {
      toast.error('Veuillez saisir votre prénom');
      return;
    }
    if (!orderForm.lastName.trim()) {
      toast.error('Veuillez saisir votre nom');
      return;
    }
    if (!orderForm.phone.trim()) {
      toast.error('Veuillez saisir votre numéro de téléphone');
      return;
    }
    if (!isValidAlgerianPhone(orderForm.phone)) {
      toast.error('Veuillez saisir un numéro de téléphone algérien valide (ex: 0555123456 ou +213555123456)');
      return;
    }
    if (!orderForm.wilaya) {
      toast.error('Veuillez sélectionner votre wilaya');
      return;
    }
    if (!orderForm.city.trim()) {
      toast.error('Veuillez saisir votre ville');
      return;
    }

    setSubmitting(true);

    try {
      let wilayaName;
      let shippingPrice = 0;

      if (product.freeShipping) {
        wilayaName = algerianWilayas.find(w => w.code === orderForm.wilaya)?.name;
      } else {
        const shippingZone = shippingZones.find(zone => zone.id === orderForm.wilaya);
        wilayaName = shippingZone?.wilaya;
        shippingPrice = shippingZone?.price || 0;
      }

      await addDoc(collection(db, 'orders'), {
        ...orderForm,
        wilayaName,
        productId: product.id,
        productName: product.name,
        quantity: quantity,
        productPrice: product.price,
        shippingPrice,
        totalPrice: calculateTotal(),
        status: 'pending',
        createdAt: new Date().toISOString()
      });

      toast.success(
        <div>
          <h4>Commande confirmée !</h4>
          <p>Merci pour votre confiance. Nous vous contacterons bientôt pour confirmer votre commande.</p>
        </div>,
        {
          autoClose: 5000,
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setOrderForm({
        firstName: '',
        lastName: '',
        phone: '',
        wilaya: '',
        city: '',
      });
      setQuantity(1);
    } catch (error) {
      console.error('Erreur:', error);
      toast.error('Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setSubmitting(false);
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setOrderForm(prev => ({
      ...prev,
      phone: value
    }));
    
    if (value && !isValidAlgerianPhone(value)) {
      e.target.setCustomValidity('Format: 0555123456 ou +213555123456');
    } else {
      e.target.setCustomValidity('');
    }
  };

  return (
    <ShopLayout>
      <div className="product-detail-container">
        <div className="product-hero">
          <div className="product-detail-content">
            <div className="product-gallery">
              <div className="main-image">
                {product.images?.[selectedImage] ? (
                  <img 
                    src={product.images[selectedImage].url} 
                    alt={product.name}
                  />
                ) : (
                  <div className="no-image">
                    <i className="fas fa-image"></i>
                  </div>
                )}
               
              </div>
              {product.images?.length > 1 && (
                <div className="thumbnail-grid">
                  {product.images.map((image, index) => (
                    <div 
                      key={index}
                      className={`thumbnail ${selectedImage === index ? 'active' : ''}`}
                      onClick={() => setSelectedImage(index)}
                    >
                      <img src={image.url} alt={`${product.name} - vue ${index + 1}`} />
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="product-info">
              <nav className="product-breadcrumb">
                <span>Shop</span>
                <i className="fas fa-chevron-right"></i>
                <span>{category?.name}</span>
              </nav>

              <div className="product-header">
                <h1>{product.name}</h1>
                <div className="product-meta">
                  <div className="price-badge">{product.price} DZD</div>
                  <div className={`stock-badge ${product.stock > 0 ? 'in' : 'out'}`}>
                    <i className="fas fa-box"></i>
                    {product.stock > 0 ? `${product.stock} en stock` : 'Rupture de stock'}
                  </div>
                  {product.isBio && (
                    <div className="bio-badge">
                      <i className="fas fa-leaf"></i> BIO
                    </div>
                  )}
                </div>
              </div>
              <div className="product-description">
                <h3>Description du produit</h3>
              </div>
              {product.description}


              {product.freeShipping && (
                <div className="shipping-info">
                  <i className="fas fa-truck"></i>
                  <div>
                    <h4>Livraison gratuite</h4>
                    <p>Pour cette commande</p>
                  </div>
                </div>
              )}

              
            </div>
          </div>
        </div>

        <div className="order-section-container">
          <div className="order-section-content">
            <h2>Commander ce produit</h2>
            
            {orderStatus && (
              <div className={`alert ${orderStatus.type}`}>
                {orderStatus.message}
              </div>
            )}

            <form onSubmit={handleSubmitOrder} className="order-form">
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="firstName">Prénom</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={orderForm.firstName}
                    onChange={handleOrderChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Nom</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={orderForm.lastName}
                    onChange={handleOrderChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="phone">
                  Numéro de téléphone
                  <span className="input-hint">(Format: 0555123456 ou +213555123456)</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={orderForm.phone}
                  onChange={handlePhoneChange}
                  pattern="^(?:\+213|0)[5-7]\d{8}$"
                  required
                  className={orderForm.phone && !isValidAlgerianPhone(orderForm.phone) ? 'invalid' : ''}
                />
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="wilaya">Wilaya</label>
                  <select
                    id="wilaya"
                    name="wilaya"
                    value={orderForm.wilaya}
                    onChange={handleOrderChange}
                    required
                  >
                    <option value="">Sélectionner une wilaya</option>
                    {product.freeShipping ? (
                      algerianWilayas.map(wilaya => (
                        <option key={wilaya.code} value={wilaya.code}>
                          {wilaya.name}
                        </option>
                      ))
                    ) : (
                      shippingZones.map(zone => (
                        <option key={zone.id} value={zone.id}>
                          {zone.wilaya} ({zone.price} DZD)
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="city">Ville</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={orderForm.city}
                    onChange={handleOrderChange}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="quantity">Quantité</label>
                  <div className="quantity-selector">
                    <button 
                      type="button"
                      onClick={() => setQuantity(Math.max(1, quantity - 1))}
                      disabled={quantity <= 1}
                    >
                      <i className="fas fa-minus"></i>
                    </button>
                    <input
                      type="number"
                      id="quantity"
                      value={quantity}
                      onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                      min="1"
                      max={product.stock}
                    />
                    <button 
                      type="button"
                      onClick={() => setQuantity(Math.min(product.stock, quantity + 1))}
                      disabled={quantity >= product.stock}
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="order-summary">
                <div className="summary-header">
                  <h3>Résumé de votre commande</h3>
                </div>
                
                <div className="summary-content">
                  <div className="summary-row">
                    <div className="label">
                      <i className="fas fa-tag"></i>
                      Prix unitaire
                    </div>
                    <div className="value">{product.price} DZD</div>
                  </div>
                  
                  <div className="summary-row">
                    <div className="label">
                      <i className="fas fa-box"></i>
                      Quantité
                    </div>
                    <div className="value">{quantity}</div>
                  </div>
                  
                  <div className="summary-row">
                    <div className="label">
                      <i className="fas fa-calculator"></i>
                      Sous-total
                    </div>
                    <div className="value">{product.price * quantity} DZD</div>
                  </div>
                  
                  {!product.freeShipping && (
                    <div className="summary-row shipping-cost">
                      <div className="label">
                        <i className="fas fa-truck"></i>
                        Frais de livraison
                      </div>
                      <div className="value">
                        {orderForm.wilaya 
                          ? `${shippingZones.find(zone => zone.id === orderForm.wilaya)?.price || 0} DZD`
                          : "Sélectionnez une wilaya"
                        }
                      </div>
                    </div>
                  )}
                  
                  <div className="summary-row total">
                    <div className="label">
                      <i className="fas fa-receipt"></i>
                      Total à payer
                    </div>
                    <div className="value">{calculateTotal()} DZD</div>
                  </div>
                </div>
              </div>

              <button 
                type="submit" 
                className="submit-order-btn"
                disabled={submitting || product.stock === 0}
              >
                {submitting ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i>
                    Traitement en cours...
                  </>
                ) : (
                  <>
                    <i className="fas fa-shopping-cart"></i>
                    Confirmer la commande
                  </>
                )}
              </button>
            </form>
          </div>
        </div>

        {suggestedProducts.length > 0 && (
          <div className="suggested-products-section">
            <div className="suggested-products-content">
              <h2>Produits similaires</h2>
              <div className="suggested-products-grid">
                {suggestedProducts.map(suggestedProduct => (
                  <Link 
                    to={`/product/${suggestedProduct.id}`}
                    key={suggestedProduct.id}
                    className="suggested-product-card"
                  >
                    <div className="suggested-product-image">
                      {suggestedProduct.images?.[0] ? (
                        <img 
                          src={suggestedProduct.images[0].url} 
                          alt={suggestedProduct.name}
                          loading="lazy"
                        />
                      ) : (
                        <div className="no-image">
                          <i className="fas fa-image"></i>
                        </div>
                      )}
                    </div>
                    <div className="suggested-product-info">
                      <h3>{suggestedProduct.name}</h3>
                      <span className="price">{suggestedProduct.price} DZD</span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </ShopLayout>
  );
} 