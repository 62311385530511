import React, { useState } from 'react';
import { useData } from '../../contexts/DataContext';
import DashboardLayout from '../../components/layout/DashboardLayout';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import './Orders.css';
import { startOfMonth, endOfMonth, startOfYear, endOfYear, isWithinInterval } from 'date-fns';

const ORDER_STATUS = {
  PENDING: { value: 'pending', label: 'En attente', color: '#f59e0b' },
  CONFIRMED: { value: 'confirmed', label: 'Confirmée', color: '#3b82f6' },
  SHIPPED: { value: 'shipped', label: 'Expédiée', color: '#8b5cf6' },
  DELIVERED: { value: 'delivered', label: 'Livrée', color: '#10b981' },
  RETURNED: { value: 'returned', label: 'Retournée', color: '#ec4899' },
  CANCELLED: { value: 'cancelled', label: 'Annulée', color: '#ef4444' }
};

const ORDERS_PER_PAGE = 10;

export default function Orders() {
  const { orders, products, loading, refreshData } = useData();
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewOrder, setViewOrder] = useState(null);
  const [periodFilter, setPeriodFilter] = useState('all');
  const [customRange, setCustomRange] = useState({
    startDate: '',
    endDate: ''
  });

  // Filtrage
  const filteredOrders = orders?.filter(order => {
    const matchesSearch = (
      order.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.lastName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const matchesStatus = statusFilter === 'all' || order.status === statusFilter;
    return matchesSearch && matchesStatus;
  }) || [];

  // Tri
  const sortedOrders = [...filteredOrders].sort((a, b) => {
    if (sortConfig.key === 'createdAt') {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  // Pagination
  const totalPages = Math.ceil(sortedOrders.length / ORDERS_PER_PAGE);
  const paginatedOrders = sortedOrders.slice(
    (currentPage - 1) * ORDERS_PER_PAGE,
    currentPage * ORDERS_PER_PAGE
  );

  // Mise à jour du statut
  const handleStatusUpdate = async (orderId, newStatus) => {
    try {
      const order = orders.find(o => o.id === orderId);
      const product = products.find(p => p.id === order.productId);
      
      if (!product) {
        console.error('Produit non trouvé');
        return;
      }

      // Calculer l'ajustement du stock
      let stockAdjustment = 0;

      // Si on passe de "pending" à un statut confirmé/expédié/livré
      if (order.status === 'pending' && ['confirmed', 'shipped', 'delivered'].includes(newStatus)) {
        stockAdjustment = -order.quantity; // Réduire le stock
      }
      // Si on passe d'un statut confirmé/expédié/livré à annulé/retourné
      else if (['confirmed', 'shipped', 'delivered'].includes(order.status) && 
               ['cancelled', 'returned'].includes(newStatus)) {
        stockAdjustment = order.quantity; // Remettre le stock
      }

      // Mettre à jour le stock du produit
      if (stockAdjustment !== 0) {
        await updateDoc(doc(db, 'products', product.id), {
          stock: product.stock + stockAdjustment
        });
      }

      // Mettre à jour le statut de la commande
      await updateDoc(doc(db, 'orders', orderId), {
        status: newStatus,
        updatedAt: new Date().toISOString()
      });

      await refreshData();
      setSelectedOrder(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour:', error);
    }
  };

  // Ajouter cette fonction de filtrage par période
  const filterOrdersByPeriod = (orders) => {
    const now = new Date();
    let startDate, endDate;

    switch (periodFilter) {
      case 'month':
        startDate = startOfMonth(now);
        endDate = endOfMonth(now);
        break;
      case 'year':
        startDate = startOfYear(now);
        endDate = endOfYear(now);
        break;
      case 'custom':
        startDate = customRange.startDate ? new Date(customRange.startDate) : null;
        endDate = customRange.endDate ? new Date(customRange.endDate) : null;
        break;
      default:
        return orders;
    }

    if (periodFilter === 'custom' && (!startDate || !endDate)) {
      return orders;
    }

    return orders.filter(order => {
      const orderDate = new Date(order.createdAt);
      return isWithinInterval(orderDate, { start: startDate, end: endDate });
    });
  };

  // Appliquer le filtre de période
  const periodFilteredOrders = filterOrdersByPeriod(filteredOrders);

  // Calculer les statistiques
  const stats = {
    totalRevenue: periodFilteredOrders
      .filter(o => ['confirmed', 'shipped', 'delivered'].includes(o.status))
      .reduce((sum, order) => sum + order.totalPrice, 0),
    totalOrders: periodFilteredOrders.length,
    pendingOrders: periodFilteredOrders.filter(o => o.status === 'pending').length,
    confirmedOrders: periodFilteredOrders.filter(o => o.status === 'confirmed').length,
    shippedOrders: periodFilteredOrders.filter(o => o.status === 'shipped').length,
    deliveredOrders: periodFilteredOrders.filter(o => o.status === 'delivered').length,
    returnedOrders: periodFilteredOrders.filter(o => o.status === 'returned').length,
    cancelledOrders: periodFilteredOrders.filter(o => o.status === 'cancelled').length,
  };

  if (loading) {
    return (
      <DashboardLayout>
        <div className="loading-container">
          <div className="loading-spinner">
            <i className="fas fa-spinner fa-spin"></i>
            <p>Chargement des commandes...</p>
          </div>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="orders-container">
        <div className="orders-header">
          <h1>Gestion des Commandes</h1>
          <div className="orders-actions">
            <div className="search-box">
              <i className="fas fa-search"></i>
              <input
                type="text"
                placeholder="Rechercher une commande..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="status-filter"
            >
              <option value="all">Tous les statuts</option>
              {Object.entries(ORDER_STATUS).map(([key, { value, label }]) => (
                <option key={key} value={value}>{label}</option>
              ))}
            </select>
          </div>
          <div className="period-filters">
            <select 
              value={periodFilter} 
              onChange={(e) => setPeriodFilter(e.target.value)}
              className="period-select"
            >
              <option value="all">Toutes les périodes</option>
              <option value="month">Ce mois</option>
              <option value="year">Cette année</option>
              <option value="custom">Période personnalisée</option>
            </select>

            {periodFilter === 'custom' && (
              <div className="date-range-picker">
                <input
                  type="date"
                  value={customRange.startDate}
                  onChange={(e) => setCustomRange(prev => ({ 
                    ...prev, 
                    startDate: e.target.value 
                  }))}
                  className="date-input"
                />
                <input
                  type="date"
                  value={customRange.endDate}
                  onChange={(e) => setCustomRange(prev => ({ 
                    ...prev, 
                    endDate: e.target.value 
                  }))}
                  min={customRange.startDate}
                  className="date-input"
                />
              </div>
            )}
          </div>
        </div>

        <div className="orders-stats">
          <div className="stat-card">
            <i className="fas fa-shopping-cart"></i>
            <div className="stat-content">
              <h3>Total Commandes</h3>
              <p>{stats.totalOrders}</p>
            </div>
          </div>

          <div className="stat-card revenue">
            <i className="fas fa-money-bill-wave"></i>
            <div className="stat-content">
              <h3>Revenu {periodFilter === 'month' ? 'du mois' : 
                         periodFilter === 'year' ? 'de l\'année' : 
                         periodFilter === 'custom' ? 'de la période' : 'total'}</h3>
              <p>{stats.totalRevenue.toFixed(2)} DZD</p>
            </div>
          </div>

          <div className="stat-card">
            <i className="fas fa-clock"></i>
            <div className="stat-content">
              <h3>En Attente</h3>
              <p>{stats.pendingOrders}</p>
            </div>
          </div>

          <div className="stat-card">
            <i className="fas fa-check-circle"></i>
            <div className="stat-content">
              <h3>Livrées</h3>
              <p>{stats.deliveredOrders}</p>
            </div>
          </div>
        </div>

        <div className="orders-table-container">
          <table className="orders-table">
            <thead>
              <tr>
                <th>N° Commande</th>
                <th>Client</th>
                <th>Produit</th>
                <th>Livraison</th>
                <th>Date</th>
                <th>Montant</th>
                <th>Statut</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedOrders.map(order => {
                const product = products.find(p => p.id === order.productId);
                
                return (
                  <tr key={order.id}>
                    <td>#{order.id.slice(-6)}</td>
                    <td>
                      <div className="customer-info">
                        <span className="customer-name">
                          {order.firstName} {order.lastName}
                        </span>
                        <span className="customer-phone">{order.phone}</span>
                      </div>
                    </td>
                    <td>
                      <div className="customer-info">
                        <span className="customer-name">
                          {product?.name}
                        </span>
                        <span className="customer-phone">
                          Qté: {order.quantity}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="customer-info">
                        <span className="customer-name">
                          {order.wilayaName}
                        </span>
                        <span className="customer-phone">
                          {order.city}
                        </span>
                      </div>
                    </td>
                    <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                    <td>
                      <div className="customer-info">
                        <span className="customer-name">
                          {order.totalPrice?.toFixed(2)} DZD
                        </span>
                        <span className="customer-phone">
                          +{order.shippingPrice} DZD livraison
                        </span>
                      </div>
                    </td>
                    <td>
                      <span
                        className="status-badge"
                        style={{ backgroundColor: ORDER_STATUS[order.status?.toUpperCase()]?.color }}
                      >
                        {ORDER_STATUS[order.status?.toUpperCase()]?.label}
                      </span>
                    </td>
                    <td>
                      <div className="table-actions">
                        <button 
                          className="btn-status" 
                          onClick={() => setSelectedOrder(order)}
                          title="Changer le statut"
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                        <button 
                          className="btn-view" 
                          onClick={() => setViewOrder(order)}
                          title="Voir les détails"
                        >
                          <i className="fas fa-eye"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="pagination">
            <button
              onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
              disabled={currentPage === 1}
              className="pagination-btn"
            >
              <i className="fas fa-chevron-left"></i>
            </button>
            
            <span className="pagination-info">
              Page {currentPage} sur {totalPages}
            </span>

            <button
              onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
              disabled={currentPage === totalPages}
              className="pagination-btn"
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>

        {selectedOrder && (
          <div className="modal-overlay">
            <div className="modal">
              <div className="modal-header">
                <h2>Modifier le statut de la commande #{selectedOrder.id.slice(-6)}</h2>
                <button className="btn-close" onClick={() => setSelectedOrder(null)}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="modal-content">
                <div className="status-options">
                  {Object.entries(ORDER_STATUS).map(([key, { value, label, color }]) => (
                    <button
                      key={key}
                      className="status-option"
                      style={{ 
                        backgroundColor: selectedOrder.status === value ? color : 'transparent',
                        color: selectedOrder.status === value ? 'white' : color,
                        borderColor: color
                      }}
                      onClick={() => handleStatusUpdate(selectedOrder.id, value)}
                    >
                      {label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {viewOrder && (
          <div className="modal-overlay" onClick={() => setViewOrder(null)}>
            <div className="modal order-details-modal" onClick={e => e.stopPropagation()}>
              <div className="modal-header">
                <h2>Détails de la commande #{viewOrder.id.slice(-6)}</h2>
                <button className="btn-close" onClick={() => setViewOrder(null)}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="modal-content">
                <div className="order-details">
                  <div className="details-section">
                    <h3>Informations client</h3>
                    <div className="details-grid">
                      <div className="detail-item">
                        <span className="label">Nom complet:</span>
                        <span className="value">{viewOrder.firstName} {viewOrder.lastName}</span>
                      </div>
                      <div className="detail-item">
                        <span className="label">Téléphone:</span>
                        <span className="value">{viewOrder.phone}</span>
                      </div>
                      <div className="detail-item">
                        <span className="label">Wilaya:</span>
                        <span className="value">{viewOrder.wilayaName}</span>
                      </div>
                      <div className="detail-item">
                        <span className="label">Ville:</span>
                        <span className="value">{viewOrder.city}</span>
                      </div>
                    </div>
                  </div>

                  <div className="details-section">
                    <h3>Détails du produit</h3>
                    <div className="product-details">
                      <div className="product-image">
                        {products?.find(p => p.id === viewOrder.productId)?.images?.[0] ? (
                          <img 
                            src={products.find(p => p.id === viewOrder.productId)?.images[0].url}
                            alt={viewOrder.productName}
                          />
                        ) : (
                          <div className="no-image">
                            <i className="fas fa-image"></i>
                          </div>
                        )}
                      </div>
                      <div className="product-info">
                        <h4>{viewOrder.productName}</h4>
                        <div className="details-grid">
                          <div className="detail-item">
                            <span className="label">Prix unitaire:</span>
                            <span className="value">{viewOrder.productPrice} DZD</span>
                          </div>
                          <div className="detail-item">
                            <span className="label">Quantité:</span>
                            <span className="value">{viewOrder.quantity}</span>
                          </div>
                          <div className="detail-item">
                            <span className="label">Frais de livraison:</span>
                            <span className="value">{viewOrder.shippingPrice} DZD</span>
                          </div>
                          <div className="detail-item total">
                            <span className="label">Total:</span>
                            <span className="value">{viewOrder.totalPrice} DZD</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="details-section">
                    <h3>Statut de la commande</h3>
                    <div className="status-timeline">
                      <div className="status-info">
                        <span 
                          className="status-badge"
                          style={{ backgroundColor: ORDER_STATUS[viewOrder.status?.toUpperCase()]?.color }}
                        >
                          {ORDER_STATUS[viewOrder.status?.toUpperCase()]?.label}
                        </span>
                        <span className="status-date">
                          {new Date(viewOrder.createdAt).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button 
                  className="btn-secondary" 
                  onClick={() => setViewOrder(null)}
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
} 