import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useData } from '../../contexts/DataContext';
import ShopLayout from '../../components/layout/ShopLayout';
import './Shop.css';

export default function Shop() {
  const { products, categories, loading } = useData();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    category: 'all',
    priceRange: 'all',
    sort: 'featured',
    isBio: false,
    freeShipping: false
  });
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const maxPrice = 10000; // Prix maximum fixe pour le slider

  useEffect(() => {
    const filtered = products
      .filter(product => {
        const matchesSearch = product.name.toLowerCase().includes(filters.search.toLowerCase()) ||
                            product.description.toLowerCase().includes(filters.search.toLowerCase());
        const matchesCategory = filters.category === 'all' || product.categoryId === filters.category;
        const matchesPrice = product.price >= priceRange[0] && product.price <= priceRange[1];
        const matchesBio = !filters.isBio || product.isBio;
        const matchesShipping = !filters.freeShipping || product.freeShipping;
        
        return product.isActive && matchesSearch && matchesCategory && matchesPrice && matchesBio && matchesShipping;
      })
      .sort((a, b) => {
        switch (filters.sort) {
          case 'price-low':
            return a.price - b.price;
          case 'price-high':
            return b.price - a.price;
          case 'newest':
            return new Date(b.createdAt) - new Date(a.createdAt);
          default:
            return 0;
        }
      });

    setFilteredProducts(filtered);
  }, [products, filters, priceRange]);

  return (
    <ShopLayout>
      <div className="shop-container">
        {/* Bannière */}
        <div className="shop-banner">
          <h1>Nos Produits</h1>
          <p>Découvrez notre sélection de produits de qualité</p>
        </div>

        <div className="shop-content">
          {/* Filtres latéraux */}
          <aside className="filters-sidebar">
            <div className="filter-section">
              <h3>Recherche</h3>
              <div className="search-box">
                <i className="fas fa-search"></i>
                <input
                  type="text"
                  placeholder="Rechercher un produit..."
                  value={filters.search}
                  onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                />
              </div>
            </div>

            <div className="filter-section">
              <h3>Catégories</h3>
              <select
                value={filters.category}
                onChange={(e) => setFilters({ ...filters, category: e.target.value })}
              >
                <option value="all">Toutes les catégories</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="filter-section">
              <h3>Prix</h3>
              <div className="price-slider">
                <div className="price-inputs">
                  <div className="price-input">
                    <span>Min</span>
                    <input
                      type="number"
                      value={priceRange[0]}
                      onChange={(e) => setPriceRange([
                        Math.min(parseInt(e.target.value) || 0, priceRange[1]),
                        priceRange[1]
                      ])}
                    />
                  </div>
                  <div className="price-input">
                    <span>Max</span>
                    <input
                      type="number"
                      value={priceRange[1]}
                      onChange={(e) => setPriceRange([
                        priceRange[0],
                        Math.max(parseInt(e.target.value) || 0, priceRange[0])
                      ])}
                    />
                  </div>
                </div>
                <div className="slider-container">
                  <input
                    type="range"
                    min="0"
                    max={maxPrice}
                    value={priceRange[0]}
                    onChange={(e) => setPriceRange([parseInt(e.target.value), priceRange[1]])}
                    className="price-slider-input"
                  />
                  <input
                    type="range"
                    min="0"
                    max={maxPrice}
                    value={priceRange[1]}
                    onChange={(e) => setPriceRange([priceRange[0], parseInt(e.target.value)])}
                    className="price-slider-input"
                  />
                  <div 
                    className="slider-track" 
                    style={{
                      background: `linear-gradient(
                        to right,
                        #e9ecef ${(priceRange[0] / maxPrice) * 100}%,
                        var(--color-primary) ${(priceRange[0] / maxPrice) * 100}%,
                        var(--color-primary) ${(priceRange[1] / maxPrice) * 100}%,
                        #e9ecef ${(priceRange[1] / maxPrice) * 100}%
                      )`
                    }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="filter-section">
              <h3>Trier par</h3>
              <select
                value={filters.sort}
                onChange={(e) => setFilters({ ...filters, sort: e.target.value })}
              >
                <option value="featured">En vedette</option>
                <option value="newest">Plus récents</option>
                <option value="price-low">Prix croissant</option>
                <option value="price-high">Prix décroissant</option>
              </select>
            </div>

            <div className="filter-section checkboxes">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={filters.isBio}
                  onChange={(e) => setFilters({ ...filters, isBio: e.target.checked })}
                />
                Produits Bio
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={filters.freeShipping}
                  onChange={(e) => setFilters({ ...filters, freeShipping: e.target.checked })}
                />
                Livraison gratuite
              </label>
            </div>
          </aside>

          {/* Grille de produits */}
          <main className="products-main">
            <div className="products-header">
              <p>{filteredProducts.length} produits trouvés</p>
            </div>

            {loading ? (
              <div className="loading-container">
                <div className="loading-content">
                  <i className="fas fa-spinner fa-spin"></i>
                  <p>Chargement des produits...</p>
                </div>
              </div>
            ) : (
              <div className="products-grid">
                {filteredProducts.map(product => (
                  <Link 
                    to={`/product/${product.id}`} 
                    key={product.id} 
                    className="product-card"
                  >
                    <div className="product-image">
                      {product.images?.[0] ? (
                        <img 
                          src={product.images[0].url} 
                          alt={product.name}
                          loading="lazy"
                        />
                      ) : (
                        <div className="no-image">
                          <i className="fas fa-image"></i>
                        </div>
                      )}
                      <div className="product-badges">
                        {product.isBio && (
                          <span className="badge bio">
                            <i className="fas fa-leaf"></i> BIO
                          </span>
                        )}
                        {product.freeShipping && (
                          <span className="badge shipping">
                            <i className="fas fa-truck"></i> Gratuit
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="product-info">
                      <div className="product-category">
                        {categories.find(c => c.id === product.categoryId)?.name}
                      </div>
                      <h3>{product.name}</h3>
                      <p className="product-description">{product.description}</p>
                      <div className="product-footer">
                        <span className="price">{product.price} DZD</span>
                       
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </main>
        </div>
      </div>
    </ShopLayout>
  );
} 