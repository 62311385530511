import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/routes/PrivateRoute';
import Login from './pages/auth/Login';
import Dashboard from './pages/dashboard/Dashboard';
import Categories from './pages/categories/Categories';
import { DataProvider } from './contexts/DataContext';
import './App.css';
import Customers from './pages/customers/Customers';
import Orders from './pages/orders/Orders';
import ShippingZones from './pages/shipping/ShippingZones';
import Statistics from './pages/statistics/Statistics';
import Products from './pages/products/Products';
import Home from './pages/shop/Home';
import Messages from './pages/messages/Messages';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Shop from './pages/shop/Shop';
import ProductDetail from './pages/shop/ProductDetail';

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/dashboard",
    element: <PrivateRoute><Dashboard /></PrivateRoute>
  },
  {
    path: "/categories",
    element: <PrivateRoute><Categories /></PrivateRoute>
  },
  {
    path: "/customers",
    element: <PrivateRoute><Customers /></PrivateRoute>
  },
  {
    path: "/orders",
    element: <PrivateRoute><Orders /></PrivateRoute>
  },
  {
    path: "/shipping",
    element: <PrivateRoute><ShippingZones /></PrivateRoute>
  },
  {
    path: "/statistics",
    element: <PrivateRoute><Statistics /></PrivateRoute>
  },
  {
    path: "/products",
    element: <PrivateRoute><Products /></PrivateRoute>
  },
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/messages",
    element: <PrivateRoute><Messages /></PrivateRoute>
  },
  {
    path: "/about",
    element: <About />
  },
  {
    path: "/contact",
    element: <Contact />
  },
  {
    path: "/shop",
    element: <Shop />
  },
  {
    path: "/product/:id",
    element: <ProductDetail />
  }
]);

function App() {
  return (
    <AuthProvider>
      <DataProvider>
        <div className="App">
          <RouterProvider router={router} />
        </div>
      </DataProvider>
    </AuthProvider>
  );
}

export default App;
