import React, { useState } from 'react';
import { useData } from '../../contexts/DataContext';
import DashboardLayout from '../../components/layout/DashboardLayout';
import { startOfMonth, endOfMonth, startOfYear, endOfYear, isWithinInterval, subMonths } from 'date-fns';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  PieChart, Pie, Cell, BarChart, Bar,
  AreaChart, Area, ComposedChart, Scatter,
  RadialBarChart, RadialBar
} from 'recharts';
import './Statistics.css';

const COLORS = ['#4a6741', '#8ba888', '#e9b44c', '#9cb380', '#d4ddb7', '#ec4899', '#ef4444'];
const MONTHS = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];
const STATUS_COLORS = {
  pending: '#f59e0b',
  confirmed: '#3b82f6',
  shipped: '#8b5cf6',
  delivered: '#10b981',
  returned: '#ec4899',
  cancelled: '#ef4444'
};

// Calcul de la croissance
const calculateGrowth = (orders, key) => {
  if (!orders.length) return 0;
  
  const now = new Date();
  const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  const twoMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 2, 1);

  const currentPeriodOrders = orders.filter(order => 
    new Date(order.createdAt) >= lastMonth && new Date(order.createdAt) < now
  );
  
  const previousPeriodOrders = orders.filter(order => 
    new Date(order.createdAt) >= twoMonthsAgo && new Date(order.createdAt) < lastMonth
  );

  const currentValue = currentPeriodOrders.reduce((sum, order) => sum + order[key], 0);
  const previousValue = previousPeriodOrders.reduce((sum, order) => sum + order[key], 0);

  if (previousValue === 0) return currentValue > 0 ? 100 : 0;
  
  return ((currentValue - previousValue) / previousValue * 100).toFixed(1);
};

// Calcul du temps moyen de livraison (en jours)
const calculateAverageDeliveryTime = (orders) => {
  const deliveredOrders = orders.filter(o => o.status === 'delivered' && o.deliveredAt);
  
  if (!deliveredOrders.length) return 0;
  
  const totalDays = deliveredOrders.reduce((sum, order) => {
    const start = new Date(order.createdAt);
    const end = new Date(order.deliveredAt);
    return sum + Math.ceil((end - start) / (1000 * 60 * 60 * 24));
  }, 0);

  return (totalDays / deliveredOrders.length).toFixed(1);
};

// Calcul du taux de livraison à temps (objectif : 3 jours)
const calculateOnTimeDeliveryRate = (orders) => {
  const deliveredOrders = orders.filter(o => o.status === 'delivered' && o.deliveredAt);
  
  if (!deliveredOrders.length) return 0;
  
  const onTimeDeliveries = deliveredOrders.filter(order => {
    const start = new Date(order.createdAt);
    const end = new Date(order.deliveredAt);
    const days = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
    return days <= 3;
  });

  return ((onTimeDeliveries.length / deliveredOrders.length) * 100).toFixed(1);
};

// Calcul des commandes mensuelles
const calculateMonthlyOrders = (orders, monthName) => {
  const monthIndex = MONTHS.indexOf(monthName);
  if (monthIndex === -1) return 0;

  return orders.filter(order => {
    const orderDate = new Date(order.createdAt);
    return orderDate.getMonth() === monthIndex;
  }).length;
};

// Calcul des revenus mensuels
const calculateMonthlyRevenue = (orders, monthName) => {
  const monthIndex = MONTHS.indexOf(monthName);
  if (monthIndex === -1) return 0;

  return orders
    .filter(order => {
      const orderDate = new Date(order.createdAt);
      return orderDate.getMonth() === monthIndex;
    })
    .reduce((sum, order) => sum + order.totalPrice, 0);
};

// Calcul du taux de conversion (commandes confirmées / total des commandes)
const calculateConversionRate = (orders) => {
  if (!orders.length) return 0;
  
  const confirmedOrders = orders.filter(o => 
    ['confirmed', 'shipped', 'delivered'].includes(o.status)
  ).length;

  return ((confirmedOrders / orders.length) * 100).toFixed(1);
};

// Calcul du taux de retour
const calculateReturnRate = (orders) => {
  if (!orders.length) return 0;
  
  const returnedOrders = orders.filter(o => o.status === 'returned').length;
  return ((returnedOrders / orders.length) * 100).toFixed(1);
};

// Calcul de la satisfaction client (basé sur le taux de livraison et retours)
const calculateCustomerSatisfaction = (orders) => {
  if (!orders.length) return 0;
  
  const deliveryRate = parseFloat(calculateOnTimeDeliveryRate(orders));
  const returnRate = parseFloat(calculateReturnRate(orders));
  
  // Formule simple : (livraison à temps - retours) avec un minimum de 0
  const satisfaction = Math.max(0, deliveryRate - (returnRate * 2));
  return satisfaction.toFixed(1);
};

export default function Statistics() {
  const { orders, products, categories } = useData();
  const [periodFilter, setPeriodFilter] = useState('month');
  const [customRange, setCustomRange] = useState({
    startDate: '',
    endDate: ''
  });

  // Fonction pour filtrer par période
  const filterByPeriod = (data) => {
    const now = new Date();
    let startDate, endDate;

    switch (periodFilter) {
      case 'month':
        startDate = startOfMonth(now);
        endDate = endOfMonth(now);
        break;
      case 'year':
        startDate = startOfYear(now);
        endDate = endOfYear(now);
        break;
      case 'custom':
        startDate = customRange.startDate ? new Date(customRange.startDate) : null;
        endDate = customRange.endDate ? new Date(customRange.endDate) : null;
        break;
      default:
        return data;
    }

    return data.filter(item => {
      const itemDate = new Date(item.createdAt);
      return isWithinInterval(itemDate, { start: startDate, end: endDate });
    });
  };

  // Calcul des statistiques générales
  const filteredOrders = filterByPeriod(orders);
  const stats = {
    // Revenus
    totalRevenue: filteredOrders
      .filter(o => ['confirmed', 'shipped', 'delivered'].includes(o.status))
      .reduce((sum, order) => sum + order.totalPrice, 0),
    
    // Commandes
    totalOrders: filteredOrders.length,
    averageOrderValue: filteredOrders.length > 0 
      ? filteredOrders.reduce((sum, order) => sum + order.totalPrice, 0) / filteredOrders.length 
      : 0,
    
    // Statuts des commandes
    ordersByStatus: {
      pending: filteredOrders.filter(o => o.status === 'pending').length,
      confirmed: filteredOrders.filter(o => o.status === 'confirmed').length,
      shipped: filteredOrders.filter(o => o.status === 'shipped').length,
      delivered: filteredOrders.filter(o => o.status === 'delivered').length,
      returned: filteredOrders.filter(o => o.status === 'returned').length,
      cancelled: filteredOrders.filter(o => o.status === 'cancelled').length,
    },

    // Produits
    totalProducts: products.length,
    activeProducts: products.filter(p => p.isActive).length,
    lowStockProducts: products.filter(p => p.stock < 10).length,
    outOfStockProducts: products.filter(p => p.stock === 0).length,

    // Ventes par catégorie
    salesByCategory: categories.map(category => ({
      name: category.name,
      value: filteredOrders
        .filter(o => {
          const product = products.find(p => p.id === o.productId);
          return product?.categoryId === category.id;
        })
        .reduce((sum, order) => sum + order.totalPrice, 0)
    })),

    // Top produits
    topProducts: products
      .map(product => ({
        name: product.name,
        sales: filteredOrders
          .filter(o => o.productId === product.id)
          .reduce((sum, order) => sum + order.quantity, 0)
      }))
      .sort((a, b) => b.sales - a.sales)
      .slice(0, 5),

    // Évolution des ventes sur 6 mois
    salesTrend: Array.from({ length: 6 }, (_, i) => {
      const month = subMonths(new Date(), i);
      const monthOrders = orders.filter(order => 
        new Date(order.createdAt).getMonth() === month.getMonth() &&
        new Date(order.createdAt).getFullYear() === month.getFullYear()
      );
      
      return {
        month: month.toLocaleDateString('fr-FR', { month: 'short' }),
        ventes: monthOrders.reduce((sum, order) => sum + order.totalPrice, 0)
      };
    }).reverse()

    // Analyse des revenus
    , revenueAnalysis: {
      totalRevenue: filteredOrders
        .filter(o => ['confirmed', 'shipped', 'delivered'].includes(o.status))
        .reduce((sum, order) => sum + order.totalPrice, 0),
      shippingRevenue: filteredOrders
        .filter(o => ['confirmed', 'shipped', 'delivered'].includes(o.status))
        .reduce((sum, order) => sum + order.shippingPrice, 0),
      averageOrderValue: filteredOrders.length > 0 
        ? filteredOrders.reduce((sum, order) => sum + order.totalPrice, 0) / filteredOrders.length 
        : 0,
      revenueGrowth: calculateGrowth(orders, 'totalPrice'),
    },

    // Performance des produits
    productPerformance: {
      bestSellers: products
        .map(product => ({
          name: product.name,
          sales: filteredOrders
            .filter(o => o.productId === product.id)
            .reduce((sum, order) => sum + order.quantity, 0),
          revenue: filteredOrders
            .filter(o => o.productId === product.id)
            .reduce((sum, order) => sum + order.totalPrice, 0)
        }))
        .sort((a, b) => b.revenue - a.revenue)
        .slice(0, 5),
      
      stockStatus: {
        inStock: products.filter(p => p.stock > 10).length,
        lowStock: products.filter(p => p.stock > 0 && p.stock <= 10).length,
        outOfStock: products.filter(p => p.stock === 0).length
      }
    },

    // Analyse géographique
    geographicAnalysis: {
      // Top wilayas par nombre de commandes
      topWilayas: Object.entries(
        filteredOrders.reduce((acc, order) => {
          acc[order.wilayaName] = acc[order.wilayaName] || {
            orders: 0,
            revenue: 0,
            deliveredOrders: 0,
            returnedOrders: 0,
            averageDeliveryTime: 0,
            totalDeliveryDays: 0
          };
          
          acc[order.wilayaName].orders += 1;
          acc[order.wilayaName].revenue += order.totalPrice;
          
          if (order.status === 'delivered') {
            acc[order.wilayaName].deliveredOrders += 1;
            if (order.deliveredAt) {
              const deliveryDays = Math.ceil(
                (new Date(order.deliveredAt) - new Date(order.createdAt)) / (1000 * 60 * 60 * 24)
              );
              acc[order.wilayaName].totalDeliveryDays += deliveryDays;
            }
          }
          
          if (order.status === 'returned') {
            acc[order.wilayaName].returnedOrders += 1;
          }
          
          return acc;
        }, {})
      )
        .map(([wilaya, stats]) => ({
          wilaya,
          ...stats,
          averageDeliveryTime: stats.deliveredOrders > 0 
            ? (stats.totalDeliveryDays / stats.deliveredOrders).toFixed(1)
            : 0,
          returnRate: stats.orders > 0 
            ? ((stats.returnedOrders / stats.orders) * 100).toFixed(1)
            : 0,
          successRate: stats.orders > 0
            ? ((stats.deliveredOrders / stats.orders) * 100).toFixed(1)
            : 0
        }))
        .sort((a, b) => b.orders - a.orders),

      // Statistiques globales par région
      regionalStats: {
        bestPerforming: null,
        worstPerforming: null,
        averageDeliveryTime: 0,
        totalRevenue: 0
      }
    },

    // Tendances mensuelles
    monthlyTrends: MONTHS.map(month => ({
      name: month,
      orders: calculateMonthlyOrders(filteredOrders, month),
      revenue: calculateMonthlyRevenue(filteredOrders, month)
    })),

    // KPIs supplémentaires
    kpis: {
      conversionRate: calculateConversionRate(filteredOrders),
      returnRate: calculateReturnRate(filteredOrders),
      customerSatisfaction: calculateCustomerSatisfaction(filteredOrders)
    }
  };

  return (
    <DashboardLayout>
      <div className="statistics-container">
        <div className="statistics-header">
          <h1>Statistiques</h1>
          
          {/* Filtres de période */}
          <div className="period-filters">
            <select 
              value={periodFilter} 
              onChange={(e) => setPeriodFilter(e.target.value)}
              className="period-select"
            >
              <option value="all">Toutes les périodes</option>
              <option value="month">Ce mois</option>
              <option value="year">Cette année</option>
              <option value="custom">Période personnalisée</option>
            </select>

            {periodFilter === 'custom' && (
              <div className="date-range-picker">
                <input
                  type="date"
                  value={customRange.startDate}
                  onChange={(e) => setCustomRange(prev => ({ 
                    ...prev, 
                    startDate: e.target.value 
                  }))}
                  className="date-input"
                />
                <input
                  type="date"
                  value={customRange.endDate}
                  onChange={(e) => setCustomRange(prev => ({ 
                    ...prev, 
                    endDate: e.target.value 
                  }))}
                  min={customRange.startDate}
                  className="date-input"
                />
              </div>
            )}
          </div>
        </div>

        {/* KPIs principaux */}
        <div className="stats-grid">
          <div className="stat-card revenue">
            <i className="fas fa-money-bill-wave"></i>
            <div className="stat-content">
              <h3>Revenu Total</h3>
              <p>{stats.totalRevenue.toFixed(2)} DZD</p>
            </div>
          </div>

          <div className="stat-card">
            <i className="fas fa-shopping-cart"></i>
            <div className="stat-content">
              <h3>Commandes</h3>
              <p>{stats.totalOrders}</p>
            </div>
          </div>

          <div className="stat-card">
            <i className="fas fa-chart-line"></i>
            <div className="stat-content">
              <h3>Panier Moyen</h3>
              <p>{stats.averageOrderValue.toFixed(2)} DZD</p>
            </div>
          </div>

          <div className="stat-card">
            <i className="fas fa-box"></i>
            <div className="stat-content">
              <h3>Produits Actifs</h3>
              <p>{stats.activeProducts}</p>
            </div>
          </div>

          <div className="stat-card highlight">
            <div className="stat-icon">
              <i className="fas fa-chart-line"></i>
            </div>
            <div className="stat-content">
              <h3>Croissance des revenus</h3>
              <p>{stats.revenueAnalysis.revenueGrowth}%</p>
              <span className="trend">
                vs période précédente
              </span>
            </div>
          </div>
        </div>

        {/* Graphiques */}
        <div className="charts-grid">
          {/* Évolution des ventes */}
          <div className="chart-container">
            <h3>Évolution des ventes</h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={stats.salesTrend}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="ventes" 
                  stroke="#4a6741" 
                  name="Ventes (DZD)"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* Répartition des commandes par statut */}
          <div className="chart-container">
            <h3>Statuts des commandes</h3>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={Object.entries(stats.ordersByStatus).map(([key, value]) => ({
                    name: key,
                    value: value
                  }))}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {Object.entries(stats.ordersByStatus).map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>

          {/* Top produits */}
          <div className="chart-container">
            <h3>Top 5 Produits</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={stats.topProducts}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="sales" fill="#4a6741" name="Ventes" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* Ventes par catégorie */}
          <div className="chart-container">
            <h3>Ventes par catégorie</h3>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={stats.salesByCategory}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  dataKey="value"
                >
                  {stats.salesByCategory.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>

          {/* Tendances des revenus */}
          <div className="chart-container large">
            <h3>Évolution des revenus</h3>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart data={stats.monthlyTrends}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Legend />
                <Area
                  yAxisId="left"
                  type="monotone"
                  dataKey="revenue"
                  fill="#4a6741"
                  stroke="#4a6741"
                  fillOpacity={0.3}
                  name="Revenus"
                />
                <Scatter
                  yAxisId="right"
                  dataKey="orders"
                  fill="#8ba888"
                  name="Commandes"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>

          {/* Performance des produits */}
          <div className="chart-container">
            <h3>Performance des produits</h3>
            <ResponsiveContainer width="100%" height={300}>
              <RadialBarChart
                innerRadius="30%"
                outerRadius="100%"
                data={stats.productPerformance.bestSellers}
                startAngle={180}
                endAngle={0}
              >
                <RadialBar
                  minAngle={15}
                  label={{ fill: '#666', position: 'insideStart' }}
                  background
                  clockWise={true}
                  dataKey="revenue"
                />
                <Legend />
                <Tooltip />
              </RadialBarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Tableaux détaillés */}
        <div className="detailed-stats">
          {/* Stock */}
          <div className="stats-section">
            <h3>État du stock</h3>
            <div className="stats-table">
              <div className="stats-row">
                <span>Produits en rupture</span>
                <span>{stats.outOfStockProducts}</span>
              </div>
              <div className="stats-row">
                <span>Stock faible</span>
                <span>{stats.lowStockProducts}</span>
              </div>
              <div className="stats-row">
                <span>Total produits</span>
                <span>{stats.totalProducts}</span>
              </div>
            </div>
          </div>

          {/* Commandes */}
          <div className="stats-section">
            <h3>Détail des commandes</h3>
            <div className="stats-table">
              {Object.entries(stats.ordersByStatus).map(([status, count]) => (
                <div key={status} className="stats-row">
                  <span>{status}</span>
                  <span>{count}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="stats-section wilaya-stats">
            <h3>Performance par Wilaya</h3>
            <div className="wilaya-stats-table">
              <div className="wilaya-stats-header">
                <span>Wilaya</span>
                <span>Commandes</span>
                <span>Revenu</span>
                <span>Délai moyen</span>
                <span>Taux de succès</span>
                <span>Taux de retour</span>
              </div>
              {stats.geographicAnalysis.topWilayas.map(wilaya => (
                <div key={wilaya.wilaya} className="wilaya-stats-row">
                  <span className="wilaya-name">{wilaya.wilaya}</span>
                  <span className="wilaya-orders">{wilaya.orders}</span>
                  <span className="wilaya-revenue">{wilaya.revenue.toFixed(2)} DZD</span>
                  <span className="wilaya-delivery">
                    {wilaya.averageDeliveryTime} jours
                  </span>
                  <span className={`wilaya-success-rate ${
                    parseFloat(wilaya.successRate) >= 90 ? 'high' :
                    parseFloat(wilaya.successRate) >= 70 ? 'medium' : 'low'
                  }`}>
                    {wilaya.successRate}%
                  </span>
                  <span className={`wilaya-return-rate ${
                    parseFloat(wilaya.returnRate) <= 5 ? 'good' :
                    parseFloat(wilaya.returnRate) <= 15 ? 'warning' : 'danger'
                  }`}>
                    {wilaya.returnRate}%
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="stats-section">
            <h3>Indicateurs de performance</h3>
            <div className="kpi-grid">
              <div className="kpi-item">
                <span className="kpi-label">Taux de conversion</span>
                <span className="kpi-value">{stats.kpis.conversionRate}%</span>
              </div>
              {/* ... autres KPIs ... */}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
} 