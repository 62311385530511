import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './DashboardLayout.css';

export default function DashboardLayout({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  const menuItems = [
    {
      icon: 'fas fa-home',
      text: 'Tableau de bord',
      path: '/dashboard'
    },
    {
      icon: 'fas fa-box',
      text: 'Produits',
      path: '/products'
    },
    {
      icon: 'fas fa-tags',
      text: 'Catégories',
      path: '/categories'
    },
    {
      icon: 'fas fa-shopping-cart',
      text: 'Commandes',
      path: '/orders'
    },
    {
      icon: 'fas fa-truck',
      text: 'Zones de livraison',
      path: '/shipping'
    },
    {
      icon: 'fas fa-envelope',
      text: 'Messages',
      path: '/messages'
    },
    {
      icon: 'fas fa-users',
      text: 'Clients',
      path: '/customers'
    },
    {
      icon: 'fas fa-chart-bar',
      text: 'Statistiques',
      path: '/statistics'
    }
  ];

  return (
    <div className="dashboard-layout">
      <aside className="sidebar">
        <div className="sidebar-header">
          <h2>Admin Panel</h2>
        </div>
        <nav className="sidebar-nav">
          {menuItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className={`nav-item ${location.pathname === item.path ? 'active' : ''}`}
            >
              <i className={item.icon}></i>
              <span>{item.text}</span>
            </Link>
          ))}
        </nav>
        <div className="sidebar-footer">
          <button onClick={handleLogout} className="logout-button">
            <i className="fas fa-sign-out-alt"></i>
            <span>Déconnexion</span>
          </button>
        </div>
      </aside>
      <main className="dashboard-main">
        {children}
      </main>
    </div>
  );
} 