import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import ShopLayout from '../../components/layout/ShopLayout';
import './Contact.css';

export default function Contact() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    subject: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ type: '', message: '' });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus({ type: '', message: '' });

    try {
      await addDoc(collection(db, 'messages'), {
        ...formData,
        createdAt: new Date().toISOString(),
        status: 'unread'
      });

      setStatus({
        type: 'success',
        message: 'Votre message a été envoyé avec succès !'
      });
      setFormData({
        username: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Erreur:', error);
      setStatus({
        type: 'error',
        message: 'Une erreur est survenue. Veuillez réessayer.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ShopLayout>
      <div className="contact-page">
        <div className="contact-header">
          <h1>Contactez-nous</h1>
          <p>Nous sommes là pour vous aider et répondre à toutes vos questions</p>
        </div>

        <div className="contact-container">
          <div className="contact-info-section">
            <div className="info-card">
              <i className="fas fa-map-marker-alt"></i>
              <h3>Notre Adresse</h3>
              <p>123 Rue Example, Béjaïa, Algérie</p>
            </div>
            <div className="info-card">
              <i className="fas fa-phone"></i>
              <h3>Téléphone</h3>
              <p>+213 123 456 789</p>
            </div>
            <div className="info-card">
              <i className="fas fa-envelope"></i>
              <h3>Email</h3>
              <p>contact@saldaevoyage.com</p>
            </div>
            <div className="info-card">
              <i className="fas fa-clock"></i>
              <h3>Horaires</h3>
              <p>Lun - Sam: 9h00 - 18h00</p>
            </div>
          </div>

          <div className="contact-form-section">
            <form onSubmit={handleSubmit}>
              {status.message && (
                <div className={`alert ${status.type}`}>
                  {status.message}
                </div>
              )}
              
              <div className="form-group">
                <label htmlFor="username">Nom complet</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                  placeholder="Votre nom"
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Votre email"
                />
              </div>

              <div className="form-group">
                <label htmlFor="subject">Sujet</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  placeholder="Sujet de votre message"
                />
              </div>

              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  placeholder="Votre message"
                  rows="5"
                ></textarea>
              </div>

              <button 
                type="submit" 
                className="submit-button"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i>
                    Envoi en cours...
                  </>
                ) : (
                  <>
                    <i className="fas fa-paper-plane"></i>
                    Envoyer le message
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </ShopLayout>
  );
} 