import { useState } from 'react';
import { useData } from '../../contexts/DataContext';
import { db } from '../../firebase/config';
import { doc, addDoc, updateDoc, deleteDoc, collection } from 'firebase/firestore';
import DashboardLayout from '../../components/layout/DashboardLayout';
import './ShippingZones.css';

const WILAYAS = [
  "Adrar", "Chlef", "Laghouat", "Oum El Bouaghi", "Batna", "Béjaïa", "Biskra",
  "Béchar", "Blida", "Bouira", "Tamanrasset", "Tébessa", "Tlemcen", "Tiaret",
  "Tizi Ouzou", "Alger", "Djelfa", "Jijel", "Sétif", "Saïda", "Skikda",
  "Sidi Bel Abbès", "Annaba", "Guelma", "Constantine", "Médéa", "Mostaganem",
  "M'Sila", "Mascara", "Ouargla", "Oran", "El Bayadh", "Illizi", "Bordj Bou Arréridj",
  "Boumerdès", "El Tarf", "Tindouf", "Tissemsilt", "El Oued", "Khenchela",
  "Souk Ahras", "Tipaza", "Mila", "Aïn Defla", "Naâma", "Aïn Témouchent",
  "Ghardaïa", "Relizane"
];

export default function ShippingZones() {
  const { shippingZones, refreshData } = useData();
  const [newZone, setNewZone] = useState({ wilaya: '', price: '' });
  const [editingZone, setEditingZone] = useState(null);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // Filtrer les wilayas disponibles (non encore ajoutées)
  const availableWilayas = WILAYAS.filter(
    wilaya => !shippingZones.some(zone => zone.wilaya === wilaya)
  );

  // Filtrer les zones de livraison selon la recherche
  const filteredZones = shippingZones.filter(zone =>
    zone.wilaya.toLowerCase().includes(searchTerm.toLowerCase())
  );

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (!newZone.wilaya || !newZone.price) {
        setError('Veuillez remplir tous les champs');
        return;
      }

      if (editingZone) {
        await updateDoc(doc(db, 'shippingZones', editingZone.id), {
          wilaya: newZone.wilaya,
          price: Number(newZone.price),
          updatedAt: new Date()
        });
      } else {
        await addDoc(collection(db, 'shippingZones'), {
          wilaya: newZone.wilaya,
          price: Number(newZone.price),
          createdAt: new Date()
        });
      }

      setNewZone({ wilaya: '', price: '' });
      setEditingZone(null);
      setError('');
      refreshData();
    } catch (err) {
      setError('Erreur lors de l\'enregistrement');
    }
  }

  async function handleDelete(zoneId) {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette zone de livraison ?')) {
      try {
        await deleteDoc(doc(db, 'shippingZones', zoneId));
        refreshData();
      } catch (err) {
        setError('Erreur lors de la suppression');
      }
    }
  }

  function handleEdit(zone) {
    setEditingZone(zone);
    setNewZone({
      wilaya: zone.wilaya,
      price: zone.price.toString()
    });
  }

  return (
    <DashboardLayout>
      <div className="shipping-zones-container">
        <div className="shipping-zones-header">
          <h1>Zones de Livraison</h1>
          <div className="search-box">
            <i className="fas fa-search"></i>
            <input
              type="text"
              placeholder="Rechercher une wilaya..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {error && <div className="error-alert">{error}</div>}

        <div className="shipping-zones-content">
          <div className="shipping-zones-form-container">
            <form onSubmit={handleSubmit} className="shipping-form">
              <h2>{editingZone ? 'Modifier la zone' : 'Ajouter une zone'}</h2>
              <div className="form-group">
                <label>Wilaya</label>
                {editingZone ? (
                  <input
                    type="text"
                    value={newZone.wilaya}
                    disabled
                  />
                ) : (
                  <select
                    value={newZone.wilaya}
                    onChange={(e) => setNewZone({ ...newZone, wilaya: e.target.value })}
                    required
                  >
                    <option value="">Sélectionnez une wilaya</option>
                    {availableWilayas.map(wilaya => (
                      <option key={wilaya} value={wilaya}>{wilaya}</option>
                    ))}
                  </select>
                )}
              </div>
              <div className="form-group">
                <label>Prix de livraison (DZD)</label>
                <input
                  type="number"
                  value={newZone.price}
                  onChange={(e) => setNewZone({ ...newZone, price: e.target.value })}
                  placeholder="Ex: 1000"
                  min="0"
                  required
                />
              </div>
              <div className="form-buttons">
                <button type="submit" className="btn-submit">
                  {editingZone ? 'Mettre à jour' : 'Ajouter'}
                </button>
                {editingZone && (
                  <button
                    type="button"
                    className="btn-cancel"
                    onClick={() => {
                      setEditingZone(null);
                      setNewZone({ wilaya: '', price: '' });
                    }}
                  >
                    Annuler
                  </button>
                )}
              </div>
            </form>
          </div>

          <div className="shipping-zones-list">
            <div className="zones-grid">
              {filteredZones.map(zone => (
                <div key={zone.id} className="zone-card">
                  <div className="zone-content">
                    <h3>{zone.wilaya}</h3>
                    <p className="zone-price">{zone.price} DZD</p>
                  </div>
                  <div className="zone-actions">
                    <button
                      onClick={() => handleEdit(zone)}
                      className="btn-edit"
                      title="Modifier"
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                    <button
                      onClick={() => handleDelete(zone.id)}
                      className="btn-delete"
                      title="Supprimer"
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
} 