import React, { useState } from 'react';
import { useData } from '../../contexts/DataContext';
import DashboardLayout from '../../components/layout/DashboardLayout';
import './Customers.css';

export default function Customers() {
  const { orders, loading } = useData();
  const [searchTerm, setSearchTerm] = useState('');

  // Si les données sont en cours de chargement, afficher un indicateur
  if (loading) {
    return (
      <DashboardLayout>
        <div className="loading-container">
          <i className="fas fa-spinner fa-spin"></i>
          <p>Chargement des clients...</p>
        </div>
      </DashboardLayout>
    );
  }

  // Vérifier si orders existe
  if (!orders) {
    return (
      <DashboardLayout>
        <div className="error-container">
          <i className="fas fa-exclamation-circle"></i>
          <p>Impossible de charger les données des clients.</p>
        </div>
      </DashboardLayout>
    );
  }

  // Créer un objet unique par client en utilisant le numéro de téléphone comme clé
  const uniqueCustomers = orders.reduce((acc, order) => {
    const key = order.phone;
    if (!acc[key]) {
      acc[key] = {
        firstName: order.firstName,
        lastName: order.lastName,
        phone: order.phone,
        orders: [],
        totalSpent: 0
      };
    }
    acc[key].orders.push(order);
    acc[key].totalSpent += order.totalPrice;
    return acc;
  }, {});

  // Convertir l'objet en tableau
  const customers = Object.values(uniqueCustomers);

  // Filtrer les clients selon la recherche
  const filteredCustomers = customers.filter(customer => {
    const fullName = `${customer.firstName} ${customer.lastName}`.toLowerCase();
    return (
      fullName.includes(searchTerm.toLowerCase()) ||
      customer.phone.includes(searchTerm)
    );
  });

  return (
    <DashboardLayout>
      <div className="customers-container">
        <div className="customers-header">
          <h1>Clients</h1>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Rechercher un client..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <i className="fas fa-search"></i>
          </div>
        </div>

        <div className="customers-stats">
          <div className="stat-card">
            <i className="fas fa-users"></i>
            <div className="stat-content">
              <h3>Total Clients</h3>
              <p>{customers.length}</p>
            </div>
          </div>

          <div className="stat-card">
            <i className="fas fa-shopping-cart"></i>
            <div className="stat-content">
              <h3>Commandes Moyennes</h3>
              <p>{(orders.length / customers.length).toFixed(1)}</p>
            </div>
          </div>

          <div className="stat-card">
            <i className="fas fa-money-bill-wave"></i>
            <div className="stat-content">
              <h3>Panier Moyen</h3>
              <p>
                {(customers.reduce((sum, c) => sum + c.totalSpent, 0) / orders.length).toFixed(2)} DZD
              </p>
            </div>
          </div>
        </div>

        <div className="customers-table-container">
          <table className="customers-table">
            <thead>
              <tr>
                <th>Client</th>
                <th>Téléphone</th>
                <th>Commandes</th>
                <th>Total Dépensé</th>
                <th>Dernière Commande</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers.map(customer => (
                <tr key={customer.phone}>
                  <td>
                    <div className="customer-info">
                      <span className="customer-name">
                        {customer.firstName} {customer.lastName}
                      </span>
                    </div>
                  </td>
                  <td>{customer.phone}</td>
                  <td>{customer.orders.length}</td>
                  <td>{customer.totalSpent.toFixed(2)} DZD</td>
                  <td>
                    {new Date(Math.max(...customer.orders.map(o => new Date(o.createdAt))))
                      .toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
} 