import { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  getDocs 
} from 'firebase/firestore';
import './Categories.css';
import DashboardLayout from '../../components/layout/DashboardLayout';
import { useData } from '../../contexts/DataContext';

export default function Categories() {
  const { categories, refreshData } = useData();
  const [newCategory, setNewCategory] = useState({ name: '', description: '' });
  const [editingCategory, setEditingCategory] = useState(null);
  const [error, setError] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (editingCategory) {
        await updateDoc(doc(db, 'categories', editingCategory.id), {
          name: newCategory.name,
          description: newCategory.description,
          updatedAt: new Date()
        });
        setEditingCategory(null);
      } else {
        await addDoc(collection(db, 'categories'), {
          name: newCategory.name,
          description: newCategory.description,
          createdAt: new Date()
        });
      }
      setNewCategory({ name: '', description: '' });
      refreshData();
    } catch (err) {
      setError('Erreur lors de l\'enregistrement de la catégorie');
    }
  }

  // Supprimer une catégorie
  async function handleDelete(categoryId) {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette catégorie ?')) {
      try {
        await deleteDoc(doc(db, 'categories', categoryId));
        refreshData();
      } catch (err) {
        setError('Erreur lors de la suppression de la catégorie');
      }
    }
  }

  // Éditer une catégorie
  function handleEdit(category) {
    setEditingCategory(category);
    setNewCategory({
      name: category.name,
      description: category.description
    });
  }

  return (
    <DashboardLayout>
      <div className="categories-container">
        <div className="categories-header">
          <h1>Gestion des Catégories</h1>
        </div>

        {error && <div className="error-alert">{error}</div>}

        <div className="categories-content">
          <div className="categories-form-container">
            <form onSubmit={handleSubmit} className="category-form">
              <h2>{editingCategory ? 'Modifier la catégorie' : 'Ajouter une catégorie'}</h2>
              <div className="form-group">
                <label>Nom de la catégorie</label>
                <input
                  type="text"
                  value={newCategory.name}
                  onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
                  placeholder="Ex: Fruits et Légumes"
                  required
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  value={newCategory.description}
                  onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
                  placeholder="Description de la catégorie..."
                  required
                />
              </div>
              <div className="form-buttons">
                <button type="submit" className="btn-submit">
                  {editingCategory ? 'Mettre à jour' : 'Ajouter'}
                </button>
                {editingCategory && (
                  <button
                    type="button"
                    className="btn-cancel"
                    onClick={() => {
                      setEditingCategory(null);
                      setNewCategory({ name: '', description: '' });
                    }}
                  >
                    Annuler
                  </button>
                )}
              </div>
            </form>
          </div>

          <div className="categories-list-container">
            <h2>Liste des catégories</h2>
            <div className="categories-grid">
              {categories.map(category => (
                <div key={category.id} className="category-card">
                  <div className="category-content">
                    <h3>{category.name}</h3>
                    <p>{category.description}</p>
                    <div className="category-meta">
                      <span>Créé le: {new Date(category.createdAt?.toDate()).toLocaleDateString()}</span>
                    </div>
                  </div>
                  <div className="category-actions">
                    <button
                      onClick={() => handleEdit(category)}
                      className="btn-edit"
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                    <button
                      onClick={() => handleDelete(category.id)}
                      className="btn-delete"
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
} 