import { useState } from 'react';
import { useData } from '../../contexts/DataContext';
import DashboardLayout from '../../components/layout/DashboardLayout';
import ProductModal from '../../components/modals/ProductModal';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import './Products.css';

export default function Products() {
  const { products, categories, loading, refreshData } = useData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filters, setFilters] = useState({
    search: '',
    category: 'all',
    status: 'all',
    sort: 'newest'
  });

  // Filtrage des produits
  const filteredProducts = products.filter(product => {
    const matchesSearch = product.name.toLowerCase().includes(filters.search.toLowerCase()) ||
                         product.description.toLowerCase().includes(filters.search.toLowerCase());
    const matchesCategory = filters.category === 'all' || product.categoryId === filters.category;
    const matchesStatus = filters.status === 'all' || 
                         (filters.status === 'active' && product.isActive) ||
                         (filters.status === 'inactive' && !product.isActive);
    
    return matchesSearch && matchesCategory && matchesStatus;
  }).sort((a, b) => {
    switch (filters.sort) {
      case 'newest':
        return new Date(b.createdAt) - new Date(a.createdAt);
      case 'oldest':
        return new Date(a.createdAt) - new Date(b.createdAt);
      case 'price-high':
        return b.price - a.price;
      case 'price-low':
        return a.price - b.price;
      default:
        return 0;
    }
  });

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleDelete = async (productId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce produit ?')) {
      try {
        await deleteDoc(doc(db, 'products', productId));
        await refreshData();
      } catch (err) {
        console.error('Erreur lors de la suppression:', err);
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  return (
    <DashboardLayout>
      <div className="products-container">
        {/* En-tête */}
        <div className="products-header">
          <div className="header-content">
            <h1>Produits</h1>
            <p>{filteredProducts.length} produits trouvés</p>
          </div>
          <button 
            className="btn-add"
            onClick={() => {
              setSelectedProduct(null);
              setIsModalOpen(true);
            }}
          >
            <i className="fas fa-plus"></i>
            Nouveau Produit
          </button>
        </div>

        {/* Filtres */}
        <div className="filters-bar">
          <div className="search-box">
            <i className="fas fa-search"></i>
            <input
              type="text"
              placeholder="Rechercher un produit..."
              value={filters.search}
              onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            />
          </div>

          <div className="filters-group">
            <select
              value={filters.category}
              onChange={(e) => setFilters({ ...filters, category: e.target.value })}
            >
              <option value="all">Toutes les catégories</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>

            <select
              value={filters.status}
              onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            >
              <option value="all">Tous les statuts</option>
              <option value="active">Actif</option>
              <option value="inactive">Inactif</option>
            </select>

            <select
              value={filters.sort}
              onChange={(e) => setFilters({ ...filters, sort: e.target.value })}
            >
              <option value="newest">Plus récents</option>
              <option value="oldest">Plus anciens</option>
              <option value="price-high">Prix décroissant</option>
              <option value="price-low">Prix croissant</option>
            </select>
          </div>
        </div>

        {/* Liste des produits avec état de chargement */}
        {loading ? (
          <div className="loading-container">
            <div className="loading-content">
              <i className="fas fa-spinner fa-spin"></i>
              <p>Chargement des produits...</p>
            </div>
          </div>
        ) : (
          <div className="products-grid">
            {filteredProducts.map(product => (
              <div key={product.id} className="product-card">
                <div className="product-image">
                  {product.images?.[0] ? (
                    <img 
                      src={product.images[0].url} 
                      alt={product.name}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/placeholder-image.png';
                      }}
                      loading="lazy"
                    />
                  ) : (
                    <div className="no-image">
                      <i className="fas fa-image"></i>
                    </div>
                  )}
                  <div className="product-badges">
                    {product.isBio && <span className="badge bio">Bio</span>}
                    {product.freeShipping && <span className="badge shipping">Gratuit</span>}
                  </div>
                </div>

                <div className="product-content">
                  <div className="product-header">
                    <span className="category">
                      {categories.find(c => c.id === product.categoryId)?.name}
                    </span>
                    <span className={`status ${product.isActive ? 'active' : 'inactive'}`}>
                      {product.isActive ? 'Actif' : 'Inactif'}
                    </span>
                  </div>

                  <h3>{product.name}</h3>
                  <p className="description">{product.description}</p>

                  <div className="product-footer">
                    <div className="price-stock">
                      <span className="price">{product.price} DZD</span>
                      <span className="stock">
                        <i className="fas fa-box"></i>
                        {product.stock}
                      </span>
                    </div>
                    <div className="actions">
                      <button 
                        className="btn-edit"
                        onClick={() => handleEdit(product)}
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                      <button 
                        className="btn-delete"
                        onClick={() => handleDelete(product.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Modal d'ajout/modification */}
        <ProductModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          product={selectedProduct}
        />
      </div>
    </DashboardLayout>
  );
} 