import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDN4Q8JunX1EoxaZVxvfrNR-Jx8IoJDnlI",
    authDomain: "compta-a9ea8.firebaseapp.com",
    projectId: "compta-a9ea8",
    storageBucket: "compta-a9ea8.firebasestorage.app",
    messagingSenderId: "252060531732",
    appId: "1:252060531732:web:c44c4875812c297caf7b01"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app; 