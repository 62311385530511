import { useState } from 'react';
import { useData } from '../../contexts/DataContext';
import DashboardLayout from '../../components/layout/DashboardLayout';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import './Messages.css';

export default function Messages() {
  const { messages, loading, refreshData } = useData();
  const [filters, setFilters] = useState({
    search: '',
    status: 'all',
    sort: 'newest'
  });

  console.log('Messages from context:', messages);

  // Filtrage des messages
  const filteredMessages = messages?.filter(message => {
    const searchTerm = filters.search.toLowerCase();
    const matchesSearch = 
      message.username.toLowerCase().includes(searchTerm) ||
      message.email.toLowerCase().includes(searchTerm) ||
      message.subject.toLowerCase().includes(searchTerm) ||
      message.message.toLowerCase().includes(searchTerm);
    const matchesStatus = filters.status === 'all' || message.status === filters.status;
    
    return matchesSearch && matchesStatus;
  }).sort((a, b) => {
    switch (filters.sort) {
      case 'newest':
        return new Date(b.createdAt) - new Date(a.createdAt);
      case 'oldest':
        return new Date(a.createdAt) - new Date(b.createdAt);
      default:
        return 0;
    }
  });

  const handleDelete = async (messageId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce message ?')) {
      try {
        await deleteDoc(doc(db, 'messages', messageId));
        await refreshData();
      } catch (err) {
        console.error('Erreur lors de la suppression:', err);
      }
    }
  };

  const handleMarkAsRead = async (messageId, currentStatus) => {
    try {
      await updateDoc(doc(db, 'messages', messageId), {
        status: currentStatus === 'read' ? 'unread' : 'read'
      });
      await refreshData();
    } catch (err) {
      console.error('Erreur lors de la mise à jour:', err);
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <DashboardLayout>
      <div className="messages-container">
        <div className="messages-header">
          <div className="header-content">
            <h1>Messages</h1>
            <p>{filteredMessages?.length || 0} messages</p>
          </div>
        </div>

        <div className="filters-bar">
          <div className="search-box">
            <i className="fas fa-search"></i>
            <input
              type="text"
              placeholder="Rechercher dans les messages..."
              value={filters.search}
              onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            />
          </div>

          <div className="filters-group">
            <select
              value={filters.status}
              onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            >
              <option value="all">Tous les messages</option>
              <option value="unread">Non lus</option>
              <option value="read">Lus</option>
            </select>

            <select
              value={filters.sort}
              onChange={(e) => setFilters({ ...filters, sort: e.target.value })}
            >
              <option value="newest">Plus récents</option>
              <option value="oldest">Plus anciens</option>
            </select>
          </div>
        </div>

        {loading ? (
          <div className="loading-container">
            <div className="loading-content">
              <i className="fas fa-spinner fa-spin"></i>
              <p>Chargement des messages...</p>
            </div>
          </div>
        ) : (
          <div className="messages-grid">
            {filteredMessages?.map(message => (
              <div 
                key={message.id} 
                className={`message-card ${message.status}`}
              >
                <div className="message-header">
                  <div className="user-info">
                    <span className="username">
                      <i className="fas fa-user"></i>
                      {message.username}
                    </span>
                    <span className="email">
                      <i className="fas fa-envelope"></i>
                      {message.email}
                    </span>
                  </div>
                  <span className="date">
                    <i className="fas fa-clock"></i>
                    {formatDate(message.createdAt)}
                  </span>
                </div>

                <div className="message-subject">
                  <h3>{message.subject}</h3>
                </div>

                <div className="message-content">
                  <p>{message.message}</p>
                </div>

                <div className="message-footer">
                  <button 
                    className={`btn-status ${message.status === 'read' ? 'read' : 'unread'}`}
                    onClick={() => handleMarkAsRead(message.id, message.status)}
                  >
                    <i className={`fas fa-${message.status === 'read' ? 'envelope' : 'envelope-open'}`}></i>
                    {message.status === 'read' ? 'Marquer comme non lu' : 'Marquer comme lu'}
                  </button>
                  <button 
                    className="btn-delete"
                    onClick={() => handleDelete(message.id)}
                  >
                    <i className="fas fa-trash"></i>
                    Supprimer
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
} 