import React from 'react';
import Navbar from '../navigation/Navbar';
import Footer from '../footer/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ShopLayout({ children }) {
  return (
    <div className="shop-layout">
      <Navbar />
      <main>
        {children}
      </main>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
} 