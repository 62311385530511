import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>BioShop</h3>
          <p>Votre boutique de produits bio de confiance</p>
        </div>
        
        <div className="footer-section">
          <h4>Liens rapides</h4>
          <Link to="/">Accueil</Link>
          <Link to="/shop">Boutique</Link>
          <Link to="/about">À propos</Link>
          <Link to="/contact">Contact</Link>
        </div>
        
        <div className="footer-section">
          <h4>Contact</h4>
          <p>Email: contact@bioshop.com</p>
          <p>Tél: +213 555 123 456</p>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} BioShop. Tous droits réservés.</p>
      </div>
    </footer>
  );
} 