import React from 'react';
import ShopLayout from '../../components/layout/ShopLayout';
import './About.css';

export default function About() {
  const teamMembers = [
    {
      name: "Sarah Amrani",
      role: "Fondatrice & CEO",
      image: "/path-to-image.jpg", // À remplacer par vos images
      description: "Passionnée par le bio et le commerce équitable"
    },
    // Ajoutez d'autres membres si nécessaire
  ];

  const stats = [
    { number: "1000+", label: "Clients Satisfaits" },
    { number: "500+", label: "Produits Bio" },
    { number: "50+", label: "Producteurs Locaux" },
    { number: "24/7", label: "Support Client" }
  ];

  return (
    <ShopLayout>
      {/* Hero Section */}
      <section className="about-hero">
        <div className="about-hero-content">
          <h1>Notre Histoire</h1>
          <p>Découvrez l'histoire de SALDAE VOYAGE, votre partenaire de confiance pour des produits authentiques et naturels.</p>
        </div>
      </section>

      {/* Mission Section */}
      <section className="mission-section">
        <div className="container">
          <div className="mission-content">
            <div className="mission-text">
              <h2>Notre Mission</h2>
              <p>Chez SALDAE VOYAGE, notre mission est de rendre accessibles les meilleurs produits bio et naturels tout en soutenant les producteurs locaux. Nous nous engageons à :</p>
              <ul>
                <li>
                  <i className="fas fa-check-circle"></i>
                  Sélectionner rigoureusement nos produits et producteurs
                </li>
                <li>
                  <i className="fas fa-check-circle"></i>
                  Garantir la qualité et l'authenticité de chaque produit
                </li>
                <li>
                  <i className="fas fa-check-circle"></i>
                  Promouvoir une agriculture durable et responsable
                </li>
                <li>
                  <i className="fas fa-check-circle"></i>
                  Assurer un service client irréprochable
                </li>
              </ul>
            </div>
            <div className="mission-image">
              {/* Image illustrative */}
            </div>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="stats-section">
        <div className="container">
          <div className="stats-grid">
            {stats.map((stat, index) => (
              <div key={index} className="stat-item">
                <span className="stat-number">{stat.number}</span>
                <span className="stat-label">{stat.label}</span>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="values-section">
        <div className="container">
          <h2>Nos Valeurs</h2>
          <div className="values-grid">
            <div className="value-card">
              <i className="fas fa-leaf"></i>
              <h3>Qualité</h3>
              <p>Nous sélectionnons minutieusement chaque produit pour garantir une qualité exceptionnelle.</p>
            </div>
            <div className="value-card">
              <i className="fas fa-handshake"></i>
              <h3>Confiance</h3>
              <p>Nous construisons des relations durables basées sur la transparence et l'honnêteté.</p>
            </div>
            <div className="value-card">
              <i className="fas fa-heart"></i>
              <h3>Passion</h3>
              <p>Notre passion pour les produits naturels guide chacune de nos actions.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="contact-section">
        <div className="container">
          <div className="contact-content">
            <div className="contact-text">
              <h2>Contactez-nous</h2>
              <p>Nous sommes là pour répondre à toutes vos questions.</p>
              <div className="contact-info">
                <div className="contact-item">
                  <i className="fas fa-phone"></i>
                  <span>+213 123 456 789</span>
                </div>
                <div className="contact-item">
                  <i className="fas fa-envelope"></i>
                  <span>contact@saldaevoyage.com</span>
                </div>
                <div className="contact-item">
                  <i className="fas fa-map-marker-alt"></i>
                  <span>123 Rue Example, Béjaïa, Algérie</span>
                </div>
              </div>
            </div>
            <div className="social-links">
              <a href="#" className="social-link"><i className="fab fa-facebook"></i></a>
              <a href="#" className="social-link"><i className="fab fa-instagram"></i></a>
              <a href="#" className="social-link"><i className="fab fa-twitter"></i></a>
            </div>
          </div>
        </div>
      </section>
    </ShopLayout>
  );
} 