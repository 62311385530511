import React, { useState } from 'react';
import { useData } from '../../contexts/DataContext';
import DashboardLayout from '../../components/layout/DashboardLayout';
import { startOfMonth, endOfMonth, startOfYear, endOfYear, isWithinInterval } from 'date-fns';
import './Dashboard.css';

export default function Dashboard() {
  const { products, orders, loading } = useData();
  const [periodFilter, setPeriodFilter] = useState('all');
  const [customRange, setCustomRange] = useState({
    startDate: '',
    endDate: ''
  });

  // Fonction pour filtrer les commandes par période
  const filterOrdersByPeriod = (orders) => {
    const now = new Date();
    let startDate, endDate;

    switch (periodFilter) {
      case 'month':
        startDate = startOfMonth(now);
        endDate = endOfMonth(now);
        break;
      case 'year':
        startDate = startOfYear(now);
        endDate = endOfYear(now);
        break;
      case 'custom':
        startDate = customRange.startDate ? new Date(customRange.startDate) : null;
        endDate = customRange.endDate ? new Date(customRange.endDate) : null;
        break;
      default:
        return orders;
    }

    if (periodFilter === 'custom' && (!startDate || !endDate)) {
      return orders;
    }

    return orders.filter(order => {
      const orderDate = new Date(order.createdAt);
      return isWithinInterval(orderDate, { start: startDate, end: endDate });
    });
  };

  // Calcul des statistiques avec filtre de période
  const filteredOrders = filterOrdersByPeriod(orders);
  const stats = {
    totalRevenue: filteredOrders
      .filter(o => ['confirmed', 'shipped', 'delivered'].includes(o.status))
      .reduce((sum, order) => sum + order.totalPrice, 0),
    
    totalOrders: filteredOrders.length,
    pendingOrders: filteredOrders.filter(o => o.status === 'pending').length,
    deliveredOrders: filteredOrders.filter(o => o.status === 'delivered').length,
    lowStockProducts: products.filter(p => p.stock < 10).length,
    activeProducts: products.filter(p => p.isActive).length
  };

  // Commandes récentes
  const recentOrders = [...orders]
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(0, 5);

  // Produits en rupture de stock
  const lowStockProducts = products
    .filter(p => p.stock < 10)
    .slice(0, 5);

  if (loading) {
    return (
      <DashboardLayout>
        <div className="loading-container">
          <i className="fas fa-spinner fa-spin"></i>
          <p>Chargement du tableau de bord...</p>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="dashboard-container">
        <h1>Tableau de bord</h1>

        {/* Filtres de période */}
        <div className="period-filters">
          <select 
            value={periodFilter} 
            onChange={(e) => setPeriodFilter(e.target.value)}
            className="period-select"
          >
            <option value="all">Toutes les périodes</option>
            <option value="month">Ce mois</option>
            <option value="year">Cette année</option>
            <option value="custom">Période personnalisée</option>
          </select>

          {periodFilter === 'custom' && (
            <div className="date-range-picker">
              <input
                type="date"
                value={customRange.startDate}
                onChange={(e) => setCustomRange(prev => ({ 
                  ...prev, 
                  startDate: e.target.value 
                }))}
                className="date-input"
              />
              <input
                type="date"
                value={customRange.endDate}
                onChange={(e) => setCustomRange(prev => ({ 
                  ...prev, 
                  endDate: e.target.value 
                }))}
                min={customRange.startDate}
                className="date-input"
              />
            </div>
          )}
        </div>

        {/* Stats Cards */}
        <div className="stats-grid">
          <div className="stat-card">
            <i className="fas fa-shopping-cart"></i>
            <div className="stat-content">
              <h3>Commandes Totales</h3>
              <p>{stats.totalOrders}</p>
            </div>
          </div>

          <div className="stat-card">
            <i className="fas fa-money-bill-wave"></i>
            <div className="stat-content">
              <h3>Revenu {periodFilter === 'month' ? 'du mois' : 
                         periodFilter === 'year' ? 'de l\'année' : 
                         periodFilter === 'custom' ? 'de la période' : 'total'}</h3>
              <p>{stats.totalRevenue.toFixed(2)} DZD</p>
            </div>
          </div>

          <div className="stat-card">
            <i className="fas fa-clock"></i>
            <div className="stat-content">
              <h3>Commandes en Attente</h3>
              <p>{stats.pendingOrders}</p>
            </div>
          </div>

          <div className="stat-card">
            <i className="fas fa-box"></i>
            <div className="stat-content">
              <h3>Produits Actifs</h3>
              <p>{stats.activeProducts}</p>
            </div>
          </div>
        </div>

        {/* Sections du tableau de bord */}
        <div className="dashboard-sections">
          {/* Commandes récentes */}
          <div className="dashboard-section">
            <div className="section-header">
              <h2>Commandes Récentes</h2>
              <button className="view-all">Voir tout</button>
            </div>
            <div className="recent-orders">
              {recentOrders.map(order => (
                <div key={order.id} className="order-item">
                  <div className="order-info">
                    <span className="order-id">#{order.id.slice(-6)}</span>
                    <span className="order-customer">{order.firstName} {order.lastName}</span>
                  </div>
                  <div className="order-details">
                    <span className="order-amount">{order.totalPrice} DZD</span>
                    <span className={`order-status ${order.status}`}>
                      {order.status}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Produits en rupture de stock */}
          <div className="dashboard-section">
            <div className="section-header">
              <h2>Stock Faible</h2>
              <button className="view-all">Voir tout</button>
            </div>
            <div className="low-stock-products">
              {lowStockProducts.map(product => (
                <div key={product.id} className="product-item">
                  <div className="product-info">
                    <span className="product-name">{product.name}</span>
                    <span className="product-category">
                      {product.categoryName}
                    </span>
                  </div>
                  <div className="stock-info">
                    <span className={`stock-level ${product.stock === 0 ? 'out' : 'low'}`}>
                      {product.stock === 0 ? 'Rupture' : `${product.stock} en stock`}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
} 