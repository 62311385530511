import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useData } from '../../contexts/DataContext';
import ShopLayout from '../../components/layout/ShopLayout';
import './Home.css';

export default function Home() {
  const { products, categories } = useData();

  useEffect(() => {
    // Animation au scroll
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-in');
        }
      });
    }, { threshold: 0.1 });

    document.querySelectorAll('.animate').forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  const featuredProducts = products
    .filter(product => product.isActive)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(0, 6);

  const mainCategories = categories.slice(0, 4);

  const categoryIcons = {
    'Fruits & Légumes': { icon: 'carrot', color: '#4CAF50' },
    'Épicerie': { icon: 'store', color: '#FF9800' },
    'Boissons': { icon: 'wine-bottle', color: '#2196F3' },
    'Produits Laitiers': { icon: 'cheese', color: '#FFC107' },
    'Viandes': { icon: 'drumstick-bite', color: '#F44336' },
    'Boulangerie': { icon: 'bread-slice', color: '#795548' },
    'Snacks': { icon: 'cookie-bite', color: '#9C27B0' },
    'Cosmétiques': { icon: 'spa', color: '#E91E63' }
  };

  return (
    <ShopLayout>
      <section className="hero">
        <div className="hero-content animate slide-up">
          <span className="hero-subtitle">Bienvenue chez BioShop</span>
          <h1>Découvrez notre sélection de produits Bio</h1>
          <p>Des produits naturels et authentiques, directement des producteurs locaux</p>
          <div className="hero-buttons">
            <Link to="/shop" className="cta-button primary">
              Découvrir nos produits
              <i className="fas fa-arrow-right"></i>
            </Link>
            <Link to="/about" className="cta-button secondary">
              En savoir plus
            </Link>
          </div>
        </div>
        <div className="hero-stats animate fade-in">
          <div className="stat-item">
            <span className="stat-number">10+</span>
            <span className="stat-label">Produits Bio</span>
          </div>
          <div className="stat-item">
            <span className="stat-number">500+</span>
            <span className="stat-label">Clients Satisfaits</span>
          </div>
          <div className="stat-item">
            <span className="stat-number">48h</span>
            <span className="stat-label">Livraison Rapide</span>
          </div>
        </div>
      </section>

      <section className="features">
        <div className="section-header animate slide-up">
          <span className="section-subtitle">Pourquoi nous choisir ?</span>
          <h2>Nos Engagements</h2>
        </div>
        <div className="features-grid">
          {[
            { icon: 'leaf', title: '100% Bio', desc: 'Des produits certifiés biologiques' },
            { icon: 'truck', title: 'Livraison Rapide', desc: 'Partout en Algérie' },
            { icon: 'shield-alt', title: 'Livraison assurée', desc: 'Paiement à la livraison' },
            { icon: 'heart', title: 'Service Client', desc: 'Support 7j/7' }
          ].map((feature, index) => (
            <div key={index} className="feature-card animate fade-in" style={{ animationDelay: `${index * 0.1}s` }}>
              <div className="feature-icon">
                <i className={`fas fa-${feature.icon}`}></i>
              </div>
              <h3>{feature.title}</h3>
              <p>{feature.desc}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="featured-products">
        <div className="section-header animate slide-up">
          <span className="section-subtitle">Nos Meilleures Ventes</span>
          <h2>Produits Vedettes</h2>
        </div>
        <div className="products-grid">
          {featuredProducts.map((product, index) => (
            <Link 
              to={`/product/${product.id}`} 
              key={product.id} 
              className="product-card"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="product-image">
                {product.images && product.images[0] ? (
                  <img src={product.images[0].url} alt={product.name} />
                ) : (
                  <div className="no-image">
                    <i className="fas fa-image"></i>
                  </div>
                )}
                <div className="product-badges">
                  {product.freeShipping && (
                    <span className="badge shipping">
                      <i className="fas fa-truck"></i> Livraison gratuite
                    </span>
                  )}
                  {product.isBio && (
                    <span className="badge bio">
                      <i className="fas fa-leaf"></i> BIO
                    </span>
                  )}
                </div>
             
              </div>
              <div className="product-info">
                <div className="product-category">
                  {categories.find(c => c.id === product.categoryId)?.name || 'Sans catégorie'}
                </div>
                <h3>{product.name}</h3>
                <div className="product-details">
                  <p className="price">{product.price} DZD</p>
                  <div className="rating">
                    <i className="fas fa-star"></i>
                    <span>4.5</span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="view-all-container">
          <Link to="/shop" className="view-all-button animate fade-in">
            Voir tous les produits
            <i className="fas fa-arrow-right"></i>
          </Link>
        </div>
      </section>

      <section className="categories-section">
        <div className="section-header animate slide-up">
          <span className="section-subtitle">Explorer</span>
          <h2>Nos Catégories</h2>
        </div>
        <div className="categories-grid">
          {categories.map((category, index) => {
            const iconInfo = categoryIcons[category.name] || { 
              icon: 'box', 
              color: '#607D8B' 
            };
            
            return (
              <Link 
                to={`/category/${category.id}`} 
                key={category.id} 
                className="category-card animate fade-in"
                style={{ animationDelay: `${index * 0.1}s` }}
              >
                <div className="category-icon" style={{ backgroundColor: iconInfo.color }}>
                  <i className={`fas fa-${iconInfo.icon}`}></i>
                </div>
                <div className="category-content">
                  <h3>{category.name}</h3>
                  <span className="category-count">
                    {products.filter(p => p.categoryId === category.id).length} produits
                  </span>
                </div>
              </Link>
            );
          })}
        </div>
      </section>

      <section className="newsletter">
        <div className="newsletter-content animate slide-up">
          <span className="section-subtitle">Newsletter</span>
          <h2>Restez informé</h2>
          <p>Inscrivez-vous à notre newsletter pour recevoir nos offres exclusives</p>
          <form className="newsletter-form">
            <div className="input-group">
              <i className="fas fa-envelope"></i>
              <input type="email" placeholder="Votre adresse email" />
            </div>
            <button type="submit">
              S'inscrire
              <i className="fas fa-paper-plane"></i>
            </button>
          </form>
        </div>
      </section>
    </ShopLayout>
  );
} 