import { useState, useEffect } from 'react';
import { useData } from '../../contexts/DataContext';
import { db } from '../../firebase/config';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import './ProductModal.css';

export default function ProductModal({ isOpen, onClose, product = null }) {
  const { categories, refreshData } = useData();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [imageFiles, setImageFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  const initialState = {
    name: '',
    description: '',
    price: '',
    stock: '',
    categoryId: '',
    images: [],
    isActive: true,
    isBio: false,
    freeShipping: false
  };

  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (product) {
      setFormData({
        name: product.name || '',
        description: product.description || '',
        price: product.price || '',
        stock: product.stock || '',
        categoryId: product.categoryId || '',
        images: product.images || [],
        isActive: product.isActive ?? true,
        isBio: product.isBio || false,
        freeShipping: product.freeShipping || false
      });
      setPreviewImages(product.images || []);
    } else {
      setFormData(initialState);
      setPreviewImages([]);
    }
    setImageFiles([]);
    setError('');
  }, [product, isOpen]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(prev => [...prev, ...files]);

    // Créer les previews
    files.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImages(prev => [...prev, { url: reader.result, isNew: true }]);
      };
      reader.readAsDataURL(file);
    });
  };

  const removeImage = (index) => {
    setPreviewImages(prev => prev.filter((_, i) => i !== index));
    setImageFiles(prev => prev.filter((_, i) => i !== index));
  };

  const uploadToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'shopify'); // Remplacer par votre upload preset
    formData.append('cloud_name', 'diat5vmzq'); // Remplacer par votre cloud name

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/diat5vmzq/image/upload`,
        {
          method: 'POST',
          body: formData,
        }
      );
      const data = await response.json();
      return {
        url: data.secure_url,
        public_id: data.public_id
      };
    } catch (error) {
      console.error('Erreur upload Cloudinary:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const imageUrls = [...formData.images];

      // Upload new images to Cloudinary
      for (const file of imageFiles) {
        const uploadedImage = await uploadToCloudinary(file);
        imageUrls.push({
          url: uploadedImage.url,
          public_id: uploadedImage.public_id
        });
      }

      const productData = {
        ...formData,
        price: Number(formData.price),
        stock: Number(formData.stock),
        images: imageUrls,
        updatedAt: new Date().toISOString()
      };

      if (product) {
        await updateDoc(doc(db, 'products', product.id), productData);
      } else {
        await addDoc(collection(db, 'products'), {
          ...productData,
          createdAt: new Date().toISOString()
        });
      }

      await refreshData();
      onClose();
    } catch (err) {
      setError('Une erreur est survenue lors de la sauvegarde');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="product-modal">
      <div className="modal-overlay" onClick={onClose} />
      <div className="modal-container">
        <div className="modal-header">
          <h2>{product ? 'Modifier le produit' : 'Nouveau produit'}</h2>
          <button className="close-button" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            {error && (
              <div className="error-message">
                <i className="fas fa-exclamation-circle"></i>
                {error}
              </div>
            )}

            <div className="image-upload-section">
              <div className="image-preview-container">
                {previewImages.map((image, index) => (
                  <div key={index} className="image-preview">
                    <img src={image.url} alt={`Preview ${index + 1}`} />
                    <button 
                      type="button" 
                      className="remove-image"
                      onClick={() => removeImage(index)}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                ))}
                <label className="image-upload-button">
                  <i className="fas fa-plus"></i>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleImageChange}
                    hidden
                  />
                </label>
              </div>
            </div>

            <div className="form-grid">
              <div className="form-group">
                <label>Nom du produit</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({...formData, name: e.target.value})}
                  required
                />
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData({...formData, description: e.target.value})}
                  required
                />
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label>Prix (DZD)</label>
                  <input
                    type="number"
                    value={formData.price}
                    onChange={(e) => setFormData({...formData, price: e.target.value})}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Stock</label>
                  <input
                    type="number"
                    value={formData.stock}
                    onChange={(e) => setFormData({...formData, stock: e.target.value})}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Catégorie</label>
                <select
                  value={formData.categoryId}
                  onChange={(e) => setFormData({...formData, categoryId: e.target.value})}
                  required
                >
                  <option value="">Sélectionner une catégorie</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-options">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={formData.isActive}
                    onChange={(e) => setFormData({...formData, isActive: e.target.checked})}
                  />
                  <span>Produit actif</span>
                </label>

                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={formData.isBio}
                    onChange={(e) => setFormData({...formData, isBio: e.target.checked})}
                  />
                  <span>Produit Bio</span>
                </label>

                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={formData.freeShipping}
                    onChange={(e) => setFormData({...formData, freeShipping: e.target.checked})}
                  />
                  <span>Livraison gratuite</span>
                </label>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn-cancel" onClick={onClose}>
              Annuler
            </button>
            <button type="submit" className="btn-submit" disabled={loading}>
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i>
                  Chargement...
                </>
              ) : product ? (
                'Mettre à jour'
              ) : (
                'Ajouter'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
} 